jQuery(function($){


  //===============================================
  /* グロナビメガメニュー展開 */
  //===============================================
  $('#gnav ul li').has('.sub').hover(function() {
    var h = $(this).children('.sub').children('ul').outerHeight() + 6;
    $(this).children('.sub').css('height', h+'px');
    $(this).children('a').addClass('active');
    $(this).children('.sub').addClass('disp');
  }, function() {
    $(this).children('.sub').css('height', '0px');
    $(this).children('a').removeClass('active');
    $(this).children('.sub').removeClass('disp');
  });

  //===============================================
  /* ドロワーナビ展開 */
  //===============================================
  $('.js_btn_drawer').on('click',function(){
    if($(this).hasClass('active')) {
      $('#drawer').fadeOut();
      $('body').removeClass('fixed');
      $(this).removeClass('active');
      $('#overlay').removeClass('active');
      document.removeEventListener(
        'touchmove',
        scrollControll,
        {
          passive: false
        }
      );
    }else{
      $('#drawer').fadeIn();
      $('body').addClass('fixed');
      $(this).addClass('active');
      $(this).removeClass('behind');
      $('.js_btn_search_area').addClass('behind');
      $('#overlay').addClass('active');
      document.addEventListener(
        'touchmove',
        scrollControll,
        {
          passive: false
        }
      );
    }
  });

  $('a:not(.js_btn_drawer)').click(function(event) {
    $('body').removeClass('fixed');
    $('.js_btn_drawer').removeClass('active');
    $('.js_btn_search_area').removeClass('active');
    $('#overlay').removeClass('active');
    $('#drawer').fadeOut();
    $('#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      scrollControll,
      {
        passive: false
      }
    );
  });

  //オーバーレイのクリック時の処理（ドロワーナビを閉じる）
  $('#overlay').on('click touchstart',function(){
    $('body').removeClass('fixed');
    $('.js_btn_drawer,.js_btn_drawer_sub,.js_btn_search_area').removeClass('active');
    $(this).removeClass('active');
    $('#drawer,#drawer_sub,#ggl_search').fadeOut();
    document.removeEventListener(
      'touchmove',
      scrollControll,
      {
        passive: false
      }
    );
  });

  //===============================================
  /* ドロワーナビのアコーディオン */
  //===============================================
  $('.acc_list li').has('.sub').children('button').click(function(event) {
    var $p = $(this).parent('.acc_list li');
    var h = $p.children('.sub').children('ul').height();
    $p.toggleClass('active');
    if($p.hasClass('active')) {
      $(this).removeClass();
      $(this).addClass('minus');
      $p.children('.sub').css('height', h+'px');
    }else{
      $(this).removeClass();
      $(this).addClass('plus');
      $p.children('.sub').css('height', 0);
    }
  });


});

//===============================================
/* スクロールの高さ取得 */
//===============================================
function getScrollHeight(tgt, height) {
  var minusheight = window.innerHeight - height;
  $(tgt).css('height', minusheight);
}

//===============================================
/* スクロール変更 */
//===============================================
function psUpdate(obj) {
    getScrollHeight('#content', minusHeight2);
    obj.update();
}

function is_smartphone(){
  return ($(window).width() < 768);
}
